<template>




	<b-card title="Seller Invoices">
    
		<b-modal
          id="modal-2"
          title="Confirmation"
          ok-only
          ok-title="Yes"
          @ok="bulkPaidToSeller"
          :hide-footer="alertMessage == 1"
      	>
          <b-card-text>
              <span v-if="alertMessage == 0">
                
                  Are you sure you want to process further?
              </span>
              
              <span v-else-if="alertMessage == 1">
                  <span v-if="returnMessageStatus == 0">
                      <b-alert
                      variant="success"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
                  <span v-else-if="returnMessageStatus == 1">
                      <b-alert
                      variant="danger"
                      show
                      >
                      <div class="alert-body">
                          <span>{{returnMessage}}</span>
                      </div>
                      </b-alert>
                  </span>
              </span>
          </b-card-text>
      	</b-modal>
		<!-- table -->
		<vue-good-table
			ref="universal-payment-master-courier"
			mode="remote"
			:columns="columns"
			:rows="rows"
			:isLoading="isLoadingProp"
			:rtl="direction"
			:search-options="{
				enabled: true,
				externalQuery: searchTerm,
			}"
			:select-options="{
				enabled: true,
				selectOnCheckboxOnly: true,
				selectionInfoClass: 'custom-class',
				selectionText: 'rows selected',
				clearSelectionText: 'clear',
				disableSelectInfo: true,
				selectAllByGroup: true,
			}"
			:totalRows="totalRecords"
			@on-column-filter="onColumnFilter"
			:pagination-options="{
				enabled: true,
			}"
			@on-sort-change="onSortChange"
			@on-row-click="onRowClick"
			@on-cell-click="onCellClick"
		>

			<div slot="loadingContent">
				<b-spinner ref="spinner_one" variant="primary" class="mr-1" type="grow" style="width: 4rem; height: 4rem" />
			</div>

			<div slot="table-actions">
				<!-- primary -->
				<b-dropdown
					id="dropdown-right"
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					text="Action"
					variant="danger"
				>
					<b-dropdown-item v-on:click="adjustBtn" v-b-modal.modal-2>Paid To Seller</b-dropdown-item>
				</b-dropdown>
     		</div>
			
			<template slot="table-row" slot-scope="props">

				<span v-if="props.column.field === 'shipments'">
              		 {{ props.row.shipments }} 
            	</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>

				<!-- Column: Action -->
				<span v-if="props.column.field === 'action'">
					<b-button variant="primary" size="sm" v-on:click="paidToSeller(props.row['seller_id'])">Paid To Seller</b-button>
				</span>

			</template>

			<!-- pagination -->
			<template slot="pagination-bottom" slot-scope="props">
			
				<div class="d-flex justify-content-between flex-wrap">

					<!-- page length -->
					<div class="d-flex align-items-center mb-0 mt-1">
						<span class="text-nowrap"> Showing 1 to </span>
						<b-form-select v-model="pageLength" :options="pages" class="mx-1" @input="handlePageChange" />
						<span class="text-nowrap"> of {{ props.total }} entries </span>
					</div>

					<!-- pagination -->
					<div>
						<b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage" >
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</div>
				</div>
			</template>


		</vue-good-table>

			
	</b-card>


</template>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>

<script>
	import {
		BAvatar,
		BBadge,
		BPagination,
		BFormGroup,
		BFormInput,
		BFormSelect,
		BDropdownItem,
		BDropdown,
		BCard,
		BButton,
		BTabs,
		BTab,
		BFormDatepicker,
		BAlert,
		BSpinner,
		BCardText,
	} from "bootstrap-vue";
	import { VueGoodTable } from "vue-good-table";
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import { required } from "@validations";
	import "prismjs";
	import "prismjs/themes/prism-tomorrow.css";
	import Prism from "vue-prism-component";
	import store from "@/store/index";
	import Ripple from "vue-ripple-directive";
	import reportStore from '@/store'

	export default {

		components: {
			VueGoodTable,
			BFormDatepicker,
			BAlert,
			BSpinner,
			ValidationProvider,
			ValidationObserver,
			BCard,
			BTab,
			BTabs,
			BAvatar,
			BBadge,
			BPagination,
			BFormGroup,
			BFormInput,
			BFormSelect,
			Prism,
			BDropdownItem,
			BDropdown,
			BButton,
			BCardText,
		},

		directives: {
			Ripple,
		},

		
		data() {

			return {
				
				allData: null,
				log: [],
				totalRecords: 0,
				alertMessage:0,
				pageLength: 10,
				get_main_data_url: "get-seller-reconciled-payment-all",
				dir: false,
				isLoadingProp: true,
				formatter:new Intl.NumberFormat(),
				selectedCol:'',
				pages: ["10", "20", "50", "100"],
				
				columns: [
					{
						label: "Seller ID",
						field: "seller_id",
						filterOptions: {
							styleClass: "class1", // class to be added to the parent th element
							enabled: true, // enable filter for this column
							placeholder: "Filter This Thing", // placeholder for filter input
							filterValue: "", // initial populated value for this filter
							filterDropdownItems: [], // dropdown (with selected values) instead of text input
							filterFn: this.columnFilterFn, //custom filter function that
							trigger: "enter", //only trigger on enter not on keyup
						},
					},
					{
						label: "Seller Name",
						field: "seller_name",
						filterOptions: {
							styleClass: "class1", // class to be added to the parent th element
							enabled: true, // enable filter for this column
							placeholder: "Filter This Thing", // placeholder for filter input
							filterValue: "", // initial populated value for this filter
							filterDropdownItems: [], // dropdown (with selected values) instead of text input
							filterFn: this.columnFilterFn, //custom filter function that
							trigger: "enter", //only trigger on enter not on keyup
						},
					},
					{
						label: "Shipments",
						field: "shipments",
						sortable: false,
					},
					{
						label: "Amount",
						field: "amount",
						sortable: true,
					},
					{
						label: "Courier COD Charges",
						field: (row) => {
							return this.formatter.format(row.invoice_amount + row.diff);
						},
						sortable: false,
					},
					{
						label: "Action",
						field: "action",
						sortable: false,
					},
				],
				rows: [],
				serverParams: {
					// a map of column filters example: {name: 'john', age: '20'}
					columnFilters: {},
					sort: {
						field: "payment_date", // example: 'name'
						type: "desc", // 'asc' or 'desc'
					},
					page: 1, // what page I want to show
					perPage: 10, // how many items I'm showing per page
				},
				searchTerm: "",
			};
		},


		computed: {

			direction() {
				if (store.state.appConfig.isRTL) {
					this.dir = true;
					return this.dir;
				}
				this.dir = false;
				return this.dir;
			},

		},


		methods: {



			/// TABLE METHODS START ///

			updateAllData(data) {
				this.allData = null;
				this.allData = data;
			},


			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps);
			},


			handleChangePage(page) {
				this.updateParams({ page: page });
				// this.log.push(`The user changed the page to: ${page}`)
				this.loadItems();
			},


			handlePageChange(active) {
				this.updateParams({ perPage: active });
				this.log.push(`the user change page:  ${active}`);
				this.loadItems();
			},


			onSortChange(params) {
				this.updateParams({
					sort: params,
				});
				this.loadItems();
				this.log.push(`the user ordered:  ${params[0].type}`);
			},


			onColumnFilter(params) {
				this.updateParams(params);
				this.loadItems();
			},

			onRowClick(params) {
				if(this.selectedCol != 'action' && this.selectedCol != ''){
					reportStore.commit('report/SET_MAIN_TOPIC','reconciled-payments');
					reportStore.commit('report/SET_SELLER_TOPIC',params.row.seller_id);
					reportStore.commit('report/SET_COURIER_TOPIC',params.row.courier_invoice_id);
					this.$router.push({
						name: 'drilldown-report',
						params: {
							invoice_id: params.row.courier_invoice_id
						}
					})
				}
			},
			onCellClick(param){
				this.selectedCol = param.column.field;
			},


			loadItems() {

				this.isLoadingProp = true;

				this.$http
				.post(this.get_main_data_url, {
					data: this.serverParams,
				})
				.then((response) => {
					this.totalRecords = response.data.totalRecords;
					this.rows = response.data.data;
					this.updateAllData(response.data.data);
					this.isLoadingProp = false;
				})
				.catch((error) => {
					this.isLoadingProp = false;
					this.toast('danger', error.message, 'Table Data Failed');
				});
			},

			/// TABLE METHODS END ///



			// goToDrillDown: function(topic,courier){
			// 	reportStore.commit('report/SET_MAIN_TOPIC','reconciled-payments');
			// 	reportStore.commit('report/SET_SELLER_TOPIC',topic);

			// 	this.$router.push({ name: 'drilldown-report' ,
			// 		params: {
			// 			invoice_id: params.row.courier_invoice_id
			// 		}})
				
			// 	// console.log(reportStore.state.report.subTopic);
			// },

			adjustBtn(){
			
				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";
			},


			paidToSeller(seller_id) {

				if (seller_id) {

					this.isLoadingProp = true;


					/// ---	REQUEST ( Paid Payments to Seller ) --- ///
					this.$http
					.post("payments-mark-paid-to-seller", {
						seller_id: seller_id,
					})
					.then((response) => {
						this.isLoadingProp = false;
						this.toast('success', response.data.message, 'Success');
						this.loadItems();
					})
					.catch((error) => {
						this.isLoadingProp = false;
						this.toast('danger', error.message, 'Failed');
					});

				}
			},


			bulkPaidToSeller(event) {

				this.alertMessage = 0;
				this.returnMessageStatus = 0;
				this.returnMessage = "";

				var error = 0;

				if((this.$refs['universal-payment-master-courier'].selectedRows).length > 0){

					
					this.isLoadingProp = true;

					this.$http
					.post('payments-mark-paid-to-seller/bulk', this.$refs['universal-payment-master-courier'].selectedRows)
					.then((res) => {
						
						if (res.data.error == 1) {
							this.toast('danger', res.data.message, 'Mark Paid To Seller Failed');
						} else {
							this.toast('success', res.data.message, 'Mark Paid To Seller Success');
							this.loadItems();
						}
						this.isLoadingProp = false;
					})
					.catch((error) => {
						this.isLoadingProp = false;
						this.toast('danger', error.message, 'Mark Paid To Seller Failed');
					});
					
				} else{
					event.preventDefault();
					this.alertMessage = 1;
        			this.returnMessageStatus = 1;
        			this.returnMessage = "Please select a row";
				}
			},


			toast(variant = null, message = 'Toast body content', title = variant) {
				
				message = this.$createElement('strong', { class: 'mr-2' }, message)

				this.$bvToast.toast(message, {
					title: title,
					variant,
					solid: false,
				})
			},

		},
	};


</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
